import { Controller } from 'stimulus';

export default class extends Controller {
  show(e) {
    const editUrl = e.currentTarget.dataset.editUrl;
    const updateUrl = e.currentTarget.dataset.updateUrl;
    const textFormModalTarget = e.currentTarget.dataset.textFormModalTarget;
    const model = e.currentTarget.dataset.textFormModalModel;
    const attribute = e.currentTarget.dataset.textFormModalAttribute;

    bootboxWithBackdrop('dialog', $.get(editUrl), {
      size: 'large',
      buttons: {
        ok: {
          label: '登録する',
          callback: function (e) {
            const button = e.target;
            const value = document.getElementById(textFormModalTarget).value;
            let data = {};
            data[model] = {};
            data[model][attribute] = value;

            $.ajax({
              url: updateUrl,
              method: 'PUT',
              data,
              beforeSend: function () {
                button.innerText = '送信中...';
                button.disabled = true;
              },
              complete: function () {
                button.innerText = '登録する';
                button.disabled = false;
              },
            });
            return false;
          },
        },
      },
      customBackdrop: false,
      onEscape: function () {
        // HACK 二重にモーダルを開いた場合に、一番最後に開いたモーダルを閉じると.modal-openが除去されてモーダルがスクロールできなくなるため
        setTimeout(function () {
          if ($('.modal').length) {
            $('body').addClass('modal-open');
          }
        }, 500);

        return true;
      },
    });
  }
}
