import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['status'];

  changeStatus(e) {
    const status__c = e.target.value;
    const salesItemId = e.target.getAttribute('data-sales-item-id');
    this._syncStatusValue(status__c);
    this.updateSalesItem(salesItemId, { sales_item: { status__c } });
  }

  _syncStatusValue(status) {
    this.statusTargets.forEach((statusTarget) => {
      statusTarget.value = status;
    });
  }

  updateSalesItem(salesItemId, data) {
    $.ajax({
      url: `/sales_items/${salesItemId}`,
      method: 'PATCH',
      dataType: 'script',
      data,
    });
  }
}
