import { Controller } from 'stimulus';

// NOTE: http://zipcloud.ibsnet.co.jp/doc/api
export default class extends Controller {
  static targets = [
    'form',
    'postCode',
    'state',
    'city',
    'address1',
    'address2',
  ];

  initialize() {
    this.formChanged = false;
  }

  search() {
    const postCode = this.postCodeTarget.value;
    if (/\d{3}-\d{4}/.test(postCode)) {
      fetch(
        `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postCode}`
      ).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            const data = json.results[0];
            this.stateTarget.value = data.address1;
            this.cityTarget.value = data.address2;
            this.address1Target.value = data.address3;
          });
        }
      });
    } else {
      alert('ハイフンつなぎで郵便番号を入力してください。（例. 107-0061）');
    }
  }

  new() {
    if (this.confirmationRejected) {
      return false;
    }
    const realEstateId = this.data.get('real-estate-id');
    $.ajax({
      url: '/real_estates/' + realEstateId + '/owners/new',
      method: 'GET',
      dataType: 'script',
      success: () => {
        this.formChanged = false;
      },
    });
  }

  edit(e) {
    if (this.confirmationRejected) {
      return false;
    }
    const realEstateId = this.data.get('real-estate-id');
    const id = e.currentTarget.dataset.id;
    $.ajax({
      url: '/real_estates/' + realEstateId + '/owners/' + id + '/edit',
      method: 'GET',
      dataType: 'script',
      success: () => {
        this.formChanged = false;
      },
    });
  }

  submit(e) {
    e.preventDefault();

    const url = this.formTarget.getAttribute('action');
    const method = this.formTarget.dataset.method;
    const formData = new FormData(this.formTarget);
    let data = {};
    formData.forEach((value, key) => (data[key] = value));
    $.ajax({
      url,
      method,
      dataType: 'script',
      data,
      success: () => {
        this.formChanged = false;
      },
    });
  }

  changeFormState() {
    this.formChanged = true;
  }

  get confirmationRejected() {
    return (
      this.formChanged &&
      !window.confirm(
        '保存されていない変更内容は破棄されますがよろしいですか？'
      )
    );
  }
}
