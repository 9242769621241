import { Controller } from 'stimulus';
import queryString from 'query-string';

export default class extends Controller {
  onSort(e) {
    const origin = window.location.origin;
    const pathname = window.location.pathname;
    const query = queryString.parse(window.location.search, {
      arrayFormat: 'bracket',
    });
    query['sort'] = e.target.value;
    window.location.href =
      origin +
      pathname +
      '?' +
      queryString.stringify(query, { arrayFormat: 'bracket' });
  }
}
