import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'siryourl', 'bikou'];

  edit() {
    const formFields = this.element.querySelectorAll(
      'select, input, .related_material__update, .related_material__cancel'
    );
    const fields = this.element.querySelectorAll(
      'span, .related_material__edit, .related_material__delete'
    );
    formFields.forEach((el) => {
      el.classList.remove('hidden');
    });
    fields.forEach((el) => {
      el.classList.add('hidden');
    });
  }

  submit(e) {
    const innerText = e.target.innerText;
    const url = this.data.get('url');
    const method = this.data.get('method');
    const data = {
      related_material: {
        name: this.nameTarget.value,
        siryourl__c: this.siryourlTarget.value,
        bikou__c: this.bikouTarget.value,
      },
    };
    $.ajax({
      url,
      method,
      dataType: 'script',
      data,
      beforeSend: () => {
        e.target.setAttribute('disabled', 'disabled');
        e.target.innerText = '送信中...';
      },
      complete: () => {
        e.target.removeAttribute('disabled');
        e.target.innerText = innerText;
      },
    });
  }

  cancel() {
    const formFields = this.element.querySelectorAll(
      'select, input, .related_material__update, .related_material__cancel'
    );
    const fields = this.element.querySelectorAll(
      'span, .related_material__edit, .related_material__delete'
    );
    formFields.forEach((el) => {
      el.classList.add('hidden');
    });
    fields.forEach((el) => {
      el.classList.remove('hidden');
    });
  }
}
