import { Controller } from 'stimulus';

export default class extends Controller {
  new() {
    const url = this.data.get('new-url');
    this.openFormModal(url);
  }

  edit(e) {
    const url = e.currentTarget.dataset.url;
    this.openFormModal(url);
  }

  openFormModal(url) {
    $.ajax({
      url,
      method: 'GET',
      beforeSend: () => {
        this.buttons.forEach((button) => button.classList.add('disabled'));
      },
      success: (message) => {
        bootbox.alert({
          message,
          size: 'large',
          backdrop: false,
          buttons: {
            ok: {
              label: '閉じる',
              className: 'btn-default',
            },
          },
        });
      },
      complete: () => {
        this.buttons.forEach((button) => button.classList.remove('disabled'));
      },
    });
  }

  get buttons() {
    return this.modal.querySelectorAll('.btn');
  }

  get modal() {
    return this.element.closest('.modal');
  }
}
