import { Controller } from 'stimulus';

export default class extends Controller {
  sync() {
    const objectName = this.data.get('object-name');
    const url = this.data.get('url');
    bootbox.confirm(
      `${objectName}を本当に同期してよろしいですか？`,
      function (result) {
        if (!result) return;

        $.ajax({
          url,
          method: 'POST',
          success: () => {
            bootbox.alert('バッチの実行を開始しました');
          },
        });
      }
    );
  }
}
