import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['complementable'];

  focusTarget(e) {
    this.complementableTargets.forEach((t) => {
      t.classList.remove('focused');
    });
    e.target.classList.add('focused');
  }

  complement(e) {
    const name = e.target.innerText;
    const focused = this.complementableTargets.find((t) => {
      return t.classList.contains('focused');
    });
    if (!focused) {
      return;
    }
    const original = focused.value;
    focused.value = original + name;
  }

  reset(e) {
    this.complementableTargets.forEach((t) => {
      t.classList.remove('focused');
    });
  }
}
