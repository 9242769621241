import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const url = window.location.pathname + window.location.search;
    $.ajax({
      url,
      method: 'GET',
      dataType: 'json',
    }).success((realEstates) => {
      if (!realEstates.length) {
        const el = document.querySelector('#map');
        el.innerHTML = '<p>該当物件がありません</p>';
        return;
      }
      const map = new CMMap('map');
      const features = realEstates.map((realEstate) => {
        const type = this.fetchType(realEstate);
        const choices = this.fetchChoices(realEstate.choices__c);
        return new CMFeature(
          realEstate.externalid__c,
          type,
          realEstate.coordinates__longitude__s,
          realEstate.coordinates__latitude__s,
          choices
        );
      });
      const jichitaicode = this.data.get('jichitaicode');
      map.addFeatures(features);
      map.setOrganization(jichitaicode);

      map.onSelectFeatures = (data) => {
        bootboxWithBackdrop('alert', $.get('/map/mylists/' + data[0].id), {
          size: 'large',
          buttons: {
            ok: {
              label: '閉じる',
              className: 'btn-default',
            },
          },
        });
      };
    });
  }

  // 種別コード: 0=空き家、1=空き地、2=駐車場、3=マンション、4=一棟アパート、5=その他、6=在宅、7=空き家対象外
  fetchType(realEstate) {
    if (realEstate.isexcluded__c === true) {
      return 7;
    }

    switch (realEstate.classification__c) {
      case '空き家':
        return 0;
      case '空き地':
        return 1;
      case '駐車場':
        return 2;
      case 'マンション':
        return 3;
      case '一棟アパート':
        return 4;
      case 'その他':
        return 5;
      case '在宅':
        return 6;
      default:
        return 0;
    }
  }

  // NOTE: 1, 2, 3以外や空の配列が設定されると物件アイコン自体が表示されなくなる
  fetchChoices(choices) {
    // NOTE: デフォルトではnullだが、一度でも同期されて未設定の場合は空の配列になる
    if (choices == null || !choices.length) {
      return null;
    }

    const choiceNumbers = choices
      .map((choice) => {
        switch (choice) {
          case '売却物件':
            return 1;
          case '賃貸物件':
            return 2;
          case '空き家バンク掲載':
            return 3;
          default:
            return 0;
        }
      })
      .filter((choiceNum) => {
        return choiceNum != 0;
      });
    if (choiceNumbers.length) {
      return choiceNumbers;
    } else {
      return null;
    }
  }
}
