import { Controller } from 'stimulus';
import Splide from '@splidejs/splide';

export default class extends Controller {
  initialize() {
    this.splide = new Splide(this.element, {
      autoWidth: true,
      perPage: this.perPage(),
      pagination: false,
    }).mount();
  }

  resizeWindow() {
    this.splide.options.perPage = this.perPage();
    this.splide.refresh();
  }

  perPage() {
    const widnowWidth = window.innerWidth;
    if (widnowWidth > 991) {
      return 3;
    }
    if (widnowWidth > 691) {
      return 2;
    }
    if (widnowWidth <= 691) {
      return 1;
    }
  }
}
