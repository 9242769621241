import { Controller } from 'stimulus';

export default class extends Controller {
  show(e) {
    const editUrl = e.currentTarget.dataset.editUrl;
    const updateUrl = e.currentTarget.dataset.updateUrl;
    const customBackdrop = e.currentTarget.dataset.customBackdrop === 'true';

    bootboxWithBackdrop('dialog', $.get(editUrl), {
      size: 'large',
      buttons: {
        ok: {
          label: '登録する',
          callback: function (e) {
            const modal = e.target.closest('.modal');
            const button = e.target;
            const classification__c = modal.querySelector(
              '#real_estate_classification__c'
            );
            const isexcluded__c = modal.querySelector(
              '#real_estate_isexcluded__c'
            );
            const classification = classification__c.value;
            const isexcluded = isexcluded__c.checked;
            const choices = modal.querySelectorAll('.choices__c:checked');
            const choices__c = Array.from(choices)
              .map((el) => el.value)
              .join(',');

            $.ajax({
              url: updateUrl,
              method: 'PUT',
              dataType: 'script',
              data: {
                real_estate: {
                  classification__c: classification,
                  isexcluded__c: isexcluded,
                },
                choices__c,
              },
              beforeSend: () => {
                button.innerText = '送信中...';
                button.disabled = true;
              },
              complete: () => {
                button.innerText = '登録する';
                button.disabled = false;
              },
            });

            return false;
          },
        },
      },
      onEscape: function () {
        if (customBackdrop) {
          // 一覧からモーダルを開いたケース
          $('.modal-backdrop').remove();
        } else {
          // 詳細からモーダルを開いたケース
          // HACK 二重にモーダルを開いた場合に、一番最後に開いたモーダルを閉じると.modal-openが除去されてモーダルがスクロールできなくなるため
          setTimeout(function () {
            if ($('.modal').length) {
              $('body').addClass('modal-open');
            }
          }, 500);
        }
      },
      customBackdrop,
    });
  }
}
