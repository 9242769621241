import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['modal'];

  show(e) {
    const src = e.target.src;
    const modal = document.getElementById('image-modal__modal');
    const modalImg = document.getElementById('image-modal__image');
    modalImg.src = src;
    modal.hidden = false;
  }

  close() {
    this.modalTarget.hidden = true;
  }
}
