import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['choicesSale', 'choicesRent', 'choicesBank'];

  changeChoicesSale(e) {
    const value = e.target.value;
    if (value === '1') {
      this.resetChoices(this.choicesRentTarget);
      this.resetChoices(this.choicesBankTarget);
      this.choicesRentTarget.disabled = true;
      this.choicesBankTarget.disabled = true;
    } else {
      this.choicesRentTarget.disabled = false;
      this.choicesBankTarget.disabled = false;
    }
  }

  changeChoicesRent(e) {
    const value = e.target.value;
    if (value === '1') {
      this.resetChoices(this.choicesSaleTarget);
      this.resetChoices(this.choicesBankTarget);
      this.choicesSaleTarget.disabled = true;
      this.choicesBankTarget.disabled = true;
    } else {
      this.choicesSaleTarget.disabled = false;
      this.choicesBankTarget.disabled = false;
    }
  }

  changeChoicesBank(e) {
    const value = e.target.value;
    if (value === '1') {
      this.resetChoices(this.choicesSaleTarget);
      this.resetChoices(this.choicesRentTarget);
      this.choicesSaleTarget.disabled = true;
      this.choicesRentTarget.disabled = true;
    } else {
      this.choicesSaleTarget.disabled = false;
      this.choicesRentTarget.disabled = false;
    }
  }

  resetChoices(target) {
    Array.from(target.children).forEach((option) => {
      option.selected = false;
    });
  }
}
