import { Controller } from 'stimulus';
import { datepickerDefaults } from '../packs/datepicker/options';

export default class extends Controller {
  static targets = ['date', 'hyoka', 'content', 'field'];

  initialize() {
    $(this.dateTarget).daterangepicker(datepickerDefaults);
    $(this.dateTarget).on('apply.daterangepicker', (_e, picker) => {
      this.dateTarget.value = picker.startDate.format('YYYY/MM/DD');
    });
  }

  toggle() {
    this.contentTargets.forEach((content) => {
      content.hidden = !content.hidden;
    });
    this.fieldTargets.forEach((field) => {
      field.hidden = !field.hidden;
    });
  }

  submit(e) {
    const innerText = e.target.innerText;
    const url = this.data.get('url');
    const method = this.data.get('method');
    $.ajax({
      url,
      method,
      dataType: 'script',
      data: {
        building_evaluation: {
          date__c: this.dateTarget.value,
          hyoka__c: this.hyokaTarget.value,
        },
      },
      beforeSend: () => {
        e.target.setAttribute('disabled', 'disabled');
        e.target.innerText = '送信中...';
      },
      complete: () => {
        e.target.removeAttribute('disabled');
        e.target.innerText = innerText;
      },
    });
  }
}
