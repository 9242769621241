import { Controller } from 'stimulus';
import { datepickerDefaults } from '../packs/datepicker/options';

export default class extends Controller {
  static targets = [
    'owner',
    'hyoujimei',
    'actionDate',
    'contents',
    'syoyusya',
    'busyo',
    'kinyusya',
    'complementable',
  ];

  initialize() {
    const options = { parentEl: '.js-histories' };
    $(this.actionDateTarget).daterangepicker(
      Object.assign({}, datepickerDefaults, options)
    );
    $(this.actionDateTarget).on('apply.daterangepicker', (_e, picker) => {
      this.actionDateTarget.value = picker.startDate.format('YYYY/MM/DD');
    });
  }

  disconnect() {
    $('.daterangepicker').remove();
  }

  edit() {
    const formFields = this.element.querySelectorAll(
      'select, input, .history__update, .history__cancel'
    );
    const fields = this.element.querySelectorAll(
      'span, .history__edit, .history__delete'
    );
    formFields.forEach((el) => {
      el.classList.remove('hidden');
    });
    fields.forEach((el) => {
      el.classList.add('hidden');
    });
  }

  cancel() {
    const formFields = this.element.querySelectorAll(
      'select, input, .history__update, .history__cancel'
    );
    const fields = this.element.querySelectorAll(
      'span, .history__edit, .history__delete'
    );
    formFields.forEach((el) => {
      el.classList.add('hidden');
    });
    fields.forEach((el) => {
      el.classList.remove('hidden');
    });
  }

  submit(e) {
    const innerText = e.target.innerText;
    const url = this.data.get('url');
    const method = this.data.get('method');
    const data = this.getDataByAccount();
    $.ajax({
      url,
      method,
      dataType: 'script',
      data,
      beforeSend: () => {
        e.target.setAttribute('disabled', 'disabled');
        e.target.innerText = '送信中...';
      },
      complete: () => {
        e.target.removeAttribute('disabled');
        e.target.innerText = innerText;
      },
    });
  }

  getDataByAccount() {
    if (this.data.get('jititai') === 'true') {
      return {
        history: {
          syoyusya__c: this.syoyusyaTarget.value,
          kinyusya__c: this.kinyusyaTarget.value,
          busyo__c: this.busyoTarget.value,
          hyoujimei__c: this.hyoujimeiTarget.value,
          action_date__c: this.actionDateTarget.value,
          contents__c: this.contentsTarget.value,
        },
      };
    } else {
      return {
        history: {
          owner__c: this.ownerTarget.value,
          hyoujimei__c: this.hyoujimeiTarget.value,
          action_date__c: this.actionDateTarget.value,
          contents__c: this.contentsTarget.value,
        },
      };
    }
  }
}
