import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content'];

  toggle(e) {
    const text = e.target.innerText;
    const hidden = this.contentTarget.hidden;

    if (hidden) {
      e.target.innerText = text.replace('表示する', '隠す');
    } else {
      e.target.innerText = text.replace('隠す', '表示する');
    }
    this.contentTarget.hidden = !hidden;
  }
}
