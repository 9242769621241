import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    const realEstate = JSON.parse(this.data.get('json'));
    const map = new CMMap('map__detail');
    const classification = this._convertClassification(
      realEstate.classification__c
    );
    const features = [
      new CMFeature(
        realEstate.externalid__c,
        classification,
        realEstate.coordinates__longitude__s,
        realEstate.coordinates__latitude__s
      ),
    ];
    map.addFeatures(features);
  }

  // 種別コード: 0=空き家、1=空き地、2=駐車場、3=マンション、4=一棟アパート、5=その他、6=在宅
  _convertClassification(classification) {
    switch (classification) {
      case '空き家':
        return 0;
      case '空き地':
        return 1;
      case '駐車場':
        return 2;
      case 'マンション':
        return 3;
      case '一棟アパート':
        return 4;
      case 'その他':
        return 5;
      case '在宅':
        return 6;
      default:
        return 0;
    }
  }
}
